import React from 'react'
import { useStyles } from './style';
import { CardContent, Typography,Card,TextField,Grid, Tabs,Tab, Button, IconButton,} from '@material-ui/core'
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import ReactSelect from 'react-select'
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import {GrSearch} from 'react-icons/gr'
import localNumberServices from "./services"
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';

function LocalNumber() {
    const classes = useStyles();
    const [value, setValue] = React.useState("1");
    const [city, setCity] = React.useState<any>([]);
    const [country, setCountry] = React.useState<any>({});
    const [cities, setCities] = React.useState([]);
    const [citiesOptions, setCityOptions] = React.useState([]);
    const [states, setStates] = React.useState([]);

    const [state, setState] = React.useState<any>([]);
    const [localNUmbers, setLocalNUmbers] = React.useState<any>([]);
    const handleTabs = (e: any, newValue: any) => {
        setValue(newValue);
    };

    const countryOptions = [
        { label: "canada", value: 1 },
        { label: "united states", value: 2 },
      ];

  // for fetching states based on country
  const onChangeCountryType = (data: any) => {
    setState([]);
    setCity([]);
    setCountry(data);
    localNumberServices.fetchStates(data.value)
      .then((res: any) => {
        if (res.success) {
          const mappedStateData: any = res.data.map((item: any) => {
            return { label: item.value, value: item.id };
          });
          setStates(mappedStateData);
        } else {
        }
      })
      .catch((err: any) => {
        // send error
      });
  };
  // for fetching cities based on state
  const onChangeStateType = (data: any) => {
    setCity([]);
    setState(data);
    localNumberServices.fetchCities(data.value)
      .then((res: any) => {
        if (res.success) {
          const mapCitiesDropdown = res.data.map((dt: any) => {
            return { label: dt.value, value: dt.id };
          });
          setCities(res.data);
          setCityOptions(mapCitiesDropdown);
          // console.log(res.data);
        } else {
        }
      })
      .catch((err: any) => { });
  };
  const onChangeCity = (data: any) => {
    setCity(data);
    // console.log("---city",data)
    const selectedCity: any = cities.find((cty: any) => cty.id === data.value);
    if (country && state && selectedCity) {
      const filterObject: any = {
        stateValue: state?.value,
        cityValue: selectedCity.id,
        npa: selectedCity?.npa,
      };
      localNumberServices.fetchLocalNumbers(filterObject)
        .then((res: any) => {

          if (res.success) {
            setLocalNUmbers(res.data);
          } else {
          }
        })
        .catch((err: any) => { });
    }
    // console.log("selected city", selectedCity);
  };

    console.log("jhbhjbdf",localNUmbers)
    return (
        <>
            <div>
                <CardContent style={{marginTop: '30px',marginLeft: 30,display:"flex"}}>
                    <FmdGoodOutlinedIcon style={{color:"#377dff"}}/>
                    <Typography>Local Number</Typography>
                </CardContent>
                <div >
                    <Grid container>
                        <Grid item md={6} style={{margin: '0px auto'}}>
                            <TabContext value={value}>
                                <Card style={{background: '#F2F2F2'}}>
                                    <Tabs
                                        className={classes.tabs}
                                        value={value}
                                        onChange={handleTabs}
                                        variant="fullWidth"
                                        TabIndicatorProps={{
                                            style: {
                                                height: "0px",
                                            },
                                        }}
                                        >
                                        <Tab
                                            label="Default"
                                            value="1"
                                            className={value === "1" ? classes.tab : classes.tabActive}
                                        />
                                        <Tab
                                            label="Repeating"
                                            value="2"
                                            className={value === "2" ? classes.tab : classes.tabActive}
                                        />
                                        <Tab
                                            label="Vanity"
                                            value="3"
                                            className={value === "3" ? classes.tab : classes.tabActive}
                                        />
                                    </Tabs>
                                </Card>
                            <TabPanel value="1">
                                <Grid container spacing={4}>
                                    <Grid item md={12}> 
                                        <Typography className={classes.header}>Country</Typography>
                                        <ReactSelect 
                                        placeholder="Canada" 
                                        options={countryOptions}
                                        onChange={onChangeCountryType}
                                        className={classes.selectField} />

                                        <Typography className={classes.headerOne}>State</Typography>
                                        <ReactSelect 
                                        placeholder="Ontario"
                                        className={classes.selectField} 
                                        onChange={onChangeStateType}
                                        options={states}/>
                                         <Typography className={classes.headerOne}>City</Typography>
                                        <ReactSelect  
                                        placeholder="435" 
                                        options={citiesOptions}
                                        onChange={onChangeCity}
                                        className={classes.selectField} />
                                        <div className={classes.bFlex} > 
                                           <div>
                                                <Button  size='small' className={classes.availableButton}>Available Numbers</Button>
                                           </div>
                                            <div>
                                                <Button  className={classes.availableButton}>Refresh Numbers</Button>
                                            </div>
                                        </div>
                                        {localNUmbers.length > 0 ? (
                                            localNUmbers.map((loc: any, i: any) => (

                                        <Button fullWidth startIcon={<CallRoundedIcon />} variant="outlined" className={classes.numButtonOne}> {loc?.number}</Button>
                                                                ))
                                        ) : (
                                        <p>No phone Numbers found</p>
                                        )}
                                     </Grid>
                                     
                                </Grid>
                            </TabPanel>
                            <TabPanel value="2">
                                <Grid container spacing={4}>
                                    <Grid item md={12}> 
                                        <Typography className={classes.header}>Country</Typography>
                                        <ReactSelect placeholder="Canada" className={classes.selectField} />
                                        <Typography className={classes.headerOne}>State</Typography>
                                        <ReactSelect  placeholder="Ontario" className={classes.selectField} />
                                         <Typography className={classes.headerOne}>Area Code</Typography>
                                        <ReactSelect  placeholder="435" className={classes.selectField} />
                                        <div className={classes.bFlex}> 
                                           <div>
                                                <Button  size='small' className={classes.availableButton}>Available Numbers</Button>
                                           </div>
                                            <div>
                                                <Button  className={classes.availableButton}>Refresh Numbers</Button>
                                            </div>
                                        </div>
                                        <Button fullWidth startIcon={<CallRoundedIcon />} variant="outlined" className={classes.numButtonOne}>(437) 292 9834</Button>
                                        <Button fullWidth startIcon={<CallRoundedIcon />} variant="outlined" className={classes.numButton}>(437) 292 9834</Button>
                                     </Grid>
                                     
                                </Grid>
                            </TabPanel>
                            <TabPanel value="3">
                                <Grid container spacing={4}>
                                    <Grid item md={12}> 
                                        <Typography className={classes.header}>Country</Typography>
                                        <ReactSelect placeholder="Canada" className={classes.selectField} />
                                        <Typography className={classes.headerOne}>State</Typography>
                                        <ReactSelect  placeholder="Ontario" className={classes.selectField} />
                                         <Typography className={classes.headerOne}>Area Code</Typography>
                                        <ReactSelect  placeholder="435" className={classes.selectField} />
                                        <div>
                                            <Typography className={classes.headerOne}>Vanity Number</Typography>
                                            <div>
                                                <ReactSelect  placeholder="435" className={classes.selectField}/>
                                                {/* <IconButton>
                                                    <GrSearch />
                                                </IconButton> */}
                                            </div>
                                        </div>
                                        <div className={classes.bFlex}> 
                                           <div>
                                                <Button  size='small' className={classes.availableButton}>Available Numbers</Button>
                                           </div>
                                            <div>
                                                <Button  className={classes.availableButton}>Refresh Numbers</Button>
                                            </div>
                                        </div>
                                        <Button fullWidth variant="outlined" className={classes.numButton}>(437) 291 9834</Button>

                                        
                                     </Grid>
                                     
                                </Grid>
                            </TabPanel>
                        

                    </TabContext>
                        </Grid>
                        <Grid item md={3}></Grid>
                    </Grid>
                   
                   
                    
                </div>
            </div>

        </>
    )
}

export default LocalNumber
