import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    headerPaper: {
        background: '#0a55df',
        padding: 9,
        position: 'sticky',
        borderRadius: 0,display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between'
    },
    paper: {
        padding: '30px 30px 80px 30px',
        width: 550,
        margin: '80px auto',
        borderRadius: 10,
        background: '#0a55df',
        '@media(max-width:768px)':{
            width:'auto',
            margin:'80px 15px 0px'
        }
    },
    paperOne: {
        textAlign: 'center'
    },
    email: {
        color: '#fff',
        fontWeight: 600
    },
    err: {
        margin: 0,
        fontSize: "0.6rem",
        color: "red",
    },
    textfield: {
        width: '80%',
        marginTop: 6,
        background: '#fff',
        //overflow: ''
    },
    confirmemail: {
        marginTop: '20px',
        color: '#fff',
        fontWeight: 600

    },
    dbg: {
        background: '#fff',
        marginTop: 20,
        padding: 8,
        width: '80%',
    },
    divFloat: {
        float: 'right',
        marginBottom: 20,
        color: '#abc8ff',
    },
    avatarBg: {
        border: '4px solid #abc8ff',
        background: '#0a55df'
    },
    icon: {
        borderRadius: 50,
        color: '#abc8ff',
        fontWeight: 600
    },
    textColor: {
        color: '#0a55df',
        marginTop: 10
    }
}))