import React from 'react'
import { useHistory } from 'react-router-dom'
import routes from '../../services/Routes'
import './headercomponent.css'
import { Typography} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch } from 'react-redux'
import logo from '../../utils/images/logo.png'
const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props: any) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: 'rgb(77, 114, 248);',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,

            },
        },
    },
}))(MenuItem);

function HeaderComponent(props: any) {
    return (
        <div className="header_component_section">
            <div className="side_header">
                <div>
                    <div className='main_header_title_main'>
                    <a href="https://efone.ca/" >
                        <img src={logo} alt="logo"  style={{width:"7%"}}/>
                        </a>
                        {/* <p className="main_header_title">{title}</p> */}
                    </div>
 
                </div>
                <div>
                    <div className="user_profile_section"  >
                        <Typography style={{ color: '#fff' }}>help@efone.ca</Typography>

                    </div>
            
                </div>
            </div>
        </div>
    )
}

export default HeaderComponent
