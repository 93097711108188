import React from "react";
import { render } from "react-dom";
import Styles from "./styy";
import { Form, Field } from "react-final-form";
import {
  Button,
  Checkbox,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  IconButton,
} from "@material-ui/core";
import Card from "./Card";
import { toast } from "react-toastify";
import axiosInstance from "../../environment/axiosInstances";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router";
import CircularProgress from "@mui/material/CircularProgress";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "./CardUtils";
import { useStyles } from "./style";
import LocalNumber from "../LocalNumber/LocalNumber";

var CryptoJS = require("crypto-js");
var loader = false;
const decryptData2 = (values) => {
  // console.log("hcxbjhcjd", process.env.REACT_APP_KEY);
  var C = CryptoJS;
  var Key = C.enc.Utf8.parse("oSOhgvKFi2AbgyVwtKMKwFV8pSc5kyxU");
  var IV = C.enc.Utf8.parse("oSOhgvKFi2AbgyVw");
  const enc = CryptoJS.AES.encrypt(values, Key, {
    iv: IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  const final = enc.ciphertext.toString(CryptoJS.enc.Base64);
  return final;
};

const onSubmit = (values, history, handlecall) => {
  loader = true;
  handlecall(true);
  var newexpiryMonth = values.expiry.split("/");
  var newNumber = values.number.replace(/ /g, "");
  var object = {};
  object["number"] = decryptData2(newNumber);
  object["expMonth"] = decryptData2(newexpiryMonth[0]);
  object["expYear"] = decryptData2("20" + newexpiryMonth[1]);
  object["cvc"] = decryptData2(values.cvc);
  axiosInstance
    .post(`/sign-up/checkout?token=${localStorage.getItem("token")}`, object)
    .then((res) => {
      if (res.data) {
        loader = false;
        handlecall(false);
        window.location.href = "https://efone.ca/signup/success";
        localStorage.removeItem("token");
        localStorage.removeItem("localNumbers");
      } else {
        loader = false;
        handlecall(false);
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    })
    .catch((err) => {
      loader = false;
      handlecall(false);
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      if (err.request.status === 402) {
        window.location.href = "https://efone.ca/pricing";
        localStorage.removeItem("token");
        localStorage.removeItem("localNumbers");
      }
    });
};

const CheckoutForm = (props) => {
  const validval = Object.keys(props.value).length;
  const [cardComplete, setCardComplete] = React.useState(false);

  const classes = useStyles();
  const history = useHistory();
  const handleChange = (e) => {
    setCardComplete(e.target.checked);
  };
  var testt =
    props.getOrderSummary.subscriptionType === 1 ? "monthly" : "annually";
  return (
    <>
      <Typography className={classes.text}>
        Your Credit card will be automatically charged ${props.total} {testt},
        as well as applicable taxes and fees.
      </Typography>
      <Typography className={classes.smallText}>
        We Offer 30 days Money Back Guarantee for Your Satisfaction
      </Typography>
      <div className={classes.conditions}>
        <Checkbox size="small" checked={cardComplete} onChange={handleChange} />
        <Typography className={classes.terms} style={{ marginLeft: "1px" }}>
          I accept Terms and Conditions
        </Typography>
      </div>
      {validval > 0 &&
      props.value.cvc &&
      props.value.number &&
      props.value.name &&
      props.value.expiry &&
      cardComplete === true ? (
        <Button
          size="small"
          fullWidth
          variant="outlined"
          style={{ background: "#4D72F8", color: "white", marginLeft: "-5px" }}
          onClick={() => onSubmit(props.value, history, props.handlecall)}
          className={classes.activeButton}
        >
          {" "}
          Start Your Activation - Lets's Go!
        </Button>
      ) : (
        <Button
          size="small"
          fullWidth
          variant="outlined"
          style={{ background: "#4D72F8", color: "white" }}
          disabled={true}
          className={classes.activeButton}
        >
          {" "}
          Start Your Activation - Lets's Go!
        </Button>
      )}
    </>
  );
};

const App = (props) => (
  <Styles>
    <Form
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        values,
        active,
      }) => {
        console.log("hjcbjdcn", values);
        return loader ? (
          <div style={{ alignItems: "center", marginTop: "100px" }}>
            <CircularProgress disableShrink />
          </div>
        ) : (
          <form>
            <Card
              number={values.number || ""}
              name={values.name || ""}
              expiry={values.expiry || ""}
              cvc={values.cvc || ""}
              focused={active}
            />
            <div>
              <Field
                name="number"
                component="input"
                type="text"
                pattern="[\d| ]{16}"
                placeholder="Card Number"
                format={formatCreditCardNumber}
              />
            </div>
            <div>
              <Field
                name="name"
                component="input"
                type="text"
                placeholder="Name"
              />
            </div>
            <div>
              <Field
                name="expiry"
                component="input"
                type="text"
                style={{ width: "127px", height: "28px" }}
                pattern="\d\d/\d\d"
                placeholder="MM/YY"
                format={formatExpirationDate}
              />
              <Field
                name="cvc"
                component="input"
                type="text"
                style={{ width: "127px", height: "28px" }}
                pattern="\d{3,4}"
                placeholder="CVC"
                format={formatCVC}
              />
            </div>
            <CheckoutForm
              value={values}
              total={props.getOrderSummary?.total}
              handlecall={props.parentCall}
              getOrderSummary={props.getOrderSummary}
            />
          </form>
        );
      }}
    />
  </Styles>
);

export default App;
