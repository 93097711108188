
import SupportComp from "../container/SupportPage/SupportPage";
import LocalNumber from "../container/LocalNumber/LocalNumber";
import TransferNumber from "../container/TransferNumber/TransferNumber";
import SigninPage from "../container/SigninPage/SigninPage";
import OrderSummary from "../container/OrderSummary/OrderSummary";
import UserProfile from "../container/UserProfile/UserProfile";
import EfoneNumbers from '../container/LocalNumber/AddEfoneNumber/AddEfoneNumber'

const routes = [
  {
    path: "/signup/cdn1/phone-number/type",
    Title: "Support",
    Component: SupportComp,
    routes: [],
  },
  {
    path: "/signup/cdn1/phone-number/local",
    Title: "Local Number",
    Component: EfoneNumbers,
    routes: [],
  },
  {
    path: "/signup/cdn1/phone-number/port/number",
    Title: "Transfer Number",
    Component: TransferNumber,
    routes: [],
  },
  {
    path: "/signin",
    Title: "Login",
    Component: SigninPage,
    routes: [],
  },
  {
    path: "/signup/cdn1/payment",
    Title: "Order Summary",
    Component: OrderSummary,
    routes: [],
  },
  {
    path: "/signup/cdn1/profile",
    Title: "User Profile",
    Component: UserProfile,
    routes: [],
  },

];

export default routes;
