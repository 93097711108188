import React from 'react';
import Layout from './container/Layout/Layout'
import { Route, Switch } from 'react-router-dom'
import Routes from './services/Routes'
import ProtectedRoutes from './components/protectedRoutes/ProtectedRoutes';
import './App.css';
import PopupAlert from './components/Popup-alert/Popup-alert';
import LogPage from './container/SigninPage/SigninPage'


function App(props: any) {

  return (
    <div className="App">
      <div className="alert_messages">
        <PopupAlert />
      </div>
      <Switch>
        <Route exact path={`/signup/cdn1/email`} component={LogPage} />
        {/* <Route path="/forgot-password/change-password" component={UpdatePassword} /> */}
        <Layout>
          {
            Routes.map((route: any, i: any) => (
              <ProtectedRoutes path={route?.path} Component={route.Component} routes={route.routes} key={i} />
            ))
          }
        </Layout>
      </Switch>
    </div>
  );
}


export default App
