import axiosInstance from '../../environment/axiosInstances'

class services {
    PostUser = async (data: any) => {

        //console.log("hhhhhh  data: "  + JSON.stringify(data))
        data = {
            "email": data.email, "confirmEmail": data.confirmEmail, "addons": data.addons, "subscriptionType": data.subscriptionType,"signUpFrom": 2
        }
        try {
            const response = await axiosInstance.post(`/sign-up`, data);

            if (response.data.data) {

                const data = response.data;
                return { success: true, data: data }
            } else {
                return { success: false, message: response.data.message }
            }
        } catch (error: any) {

            return { success: false, message: error.message === 'Network Error' ? 'Network Error' : error }
        }
    }

}
const signServices = new services()
export default signServices;