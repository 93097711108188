import { Paper, Grid, Typography, TextField, Avatar } from '@material-ui/core';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React from 'react';
import { Link } from 'react-router-dom'
import { useStyles } from './style';
import Backdrop from '../../components/Modal/Backdrop';
import ReCAPTCHA from "react-google-recaptcha";
import Modal from '../../components/Modal/Modal'
import { useHistory } from 'react-router-dom';
import SigninService from './Services'
import * as popUpActionCreator from "../../store/Actions/popup-actionCreator";
import { connect } from "react-redux";
import logo from '../../utils/images/logo.png'
import * as QueryString from "query-string"
import axiosInstance from "../../environment/axiosInstances"
import localStorageContent from '../../services/localStorage';
import { ConstructionOutlined } from '@mui/icons-material';
var axios = require('axios');
const formGroup: any = [
    {
        field: 'email',
        valid: true,
        message: ''
    },
    {
        field: 'confirmEmail',
        valid: true,
        message: ''
    },
]
function SigninPage(props: any) {
    const classes = useStyles();
    const [signInModal, setSignInModal] = React.useState(false)
    const [token, setToken] = React.useState<any>('')
    const [subType, setSubType] = React.useState<any>('')
    const [check, setCheck] = React.useState(false)
    const [formGroupData, setFormgroup] = React.useState<any>(formGroup)
    const [pasted, setPasted] = React.useState(false);
    const [addon, setAddon] = React.useState<any>([])
    const [data, setData] = React.useState({
        email: "",
        confirmEmail: "",
    

    })
    const history = useHistory()

    const handleClick = () => {
        const sendData = {
            email: data.email,
            confirmEmail: data.confirmEmail,
            addons: addon,
            subscriptionType: subType,
        }
        validateForm({ property: 'email', value: data.email })
        validateForm({ property: 'confirmEmail', value: data.confirmEmail })
        const [email, confirmEmail] = formGroupData;
        if (email.valid && confirmEmail.valid) {
            if (data.email === data.confirmEmail) {
                if (check) {
                    if (sendData.addons != "" && sendData.subscriptionType != "") {
                        SigninService.PostUser(sendData).then((res:any) => {

                            if (res.success) {
                                const data = res.data.data
                                // localStorage.setItem("token", data.signUpToken)
                                localStorageContent.setLocalUserData(data.signUpToken)
                                setAddon([])
                                setSubType('')
                                history.push("/signup/cdn1/phone-number/type")
                                props.popUpAlert(res.data.message, "UPDATE_AGENT_SUCCESS", "success");
                            }
                            else {
                                if (res.message === 'jwt expired') {
                                    window.location.href = "https://efone.ca/pricing"
                                    localStorage.clear()
                                }
                                props.popUpAlert(res.message, "UPDATE_AGENT_SUCCESS", "error");
                            }
                        }).catch(err => {
                            console.log("---err", err)
                            if (err.request.status === 402) {
                                localStorage.clear()
                                window.location.href = "https://efone.ca/pricing"
                            }
                            props.popUpAlert(err.message, "UPDATE_AGENT_SUCCESS", "error");
                        })
                    }
                    else {
                        props.popUpAlert("Addons or subscription type is missing", "UPDATE_AGENT_SUCCESS", "error");
                        localStorage.clear()
                        window.location.href = "https://efone.ca/pricing"
                    }
                }
                else {
                    props.popUpAlert("Please check recaptcha to continue", "UPDATE_AGENT_SUCCESS", "error");
                }
            }
            else {
                props.popUpAlert("Both email and confirm email should be same", "UPDATE_AGENT_SUCCESS", "error");
            }

        }
    }
    const handleChange = (e: any) => {
        const val = e.target.value
        if (val !== " ") {
            if (!pasted) {
                setData((prev: any) => {
                    return {
                        ...data,
                        [e.target.name]: e.target.value
                    }
                })
            }
            setPasted(false);
        }
        validateForm({ property: e.target.name, value: e.target.value })
    }
    // React.useEffect(() => {
    //     if (props.match.params.subscriptionType && props.match.params.addons) {
    //         var params = props.match.params
    //         var temp_sub_type = params.subscriptionType.toString()
    //         var temp_addons = params.addons.toString()
    //         var sub_type = temp_sub_type.split("subscriptionType=")
    //         var addons = temp_addons.split("addons=")
    //         addons = addons.filter((v: any) => v != '');
    //         setSubType(sub_type[1])
    //         setAddon(addons)
    //     }
    // }, [props.match.params.subscriptionType, props.match.params.addons])
    const handlePaste = () => {
        setPasted(true);
    };
    React.useEffect(() => {
        const params = QueryString.parse(props.location.search);
        setSubType(params.subscriptionType)
        if (params.addons) {
            const Newaddons: any = params.addons
            const splitedElement = Newaddons.split(',')
            setAddon(splitedElement)
        }
    }, [props.location.search])

    //Form validation
    const validateForm = (dataToValidate: any) => {
        let formgroupCopy = [...formGroupData];

        function validateRegexEmail(email: any) {
            // eslint-disable-next-line
            const re: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }

        if (dataToValidate.property === 'email') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'email') {
                        formG.valid = false;
                        formG.message = 'Email should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else if (dataToValidate.value !== '' && !validateRegexEmail(dataToValidate.value)) {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'email') {
                        formG.valid = false;
                        formG.message = 'Please provide valid email address'
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'email') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'confirmEmail') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'confirmEmail') {
                        formG.valid = false;
                        formG.message = 'confirm Email should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else if (dataToValidate.value !== '' && !validateRegexEmail(dataToValidate.value)) {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'confirmEmail') {
                        formG.valid = false;
                        formG.message = 'Please provide valid email address'
                    }
                })
                setFormgroup(formgroupCopy)
            }
            else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'confirmEmail') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'confirmEmail' && dataToValidate.property === 'email') {
            if (data.email !== data.confirmEmail) {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'email') {
                        formG.valid = false;
                        formG.message = 'Please provide both same Email address'
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }


    }
    const handleClickcheck = (value: any) => {
        setCheck(!check)
    }


    const tokenPatch = () => {
        if (token) {
            if (check) {
                axiosInstance.patch(`/sign-up/email?token=${localStorage.getItem("token")}`, data).then((res: any) => {
                    if (res.data) {
                        history.push("/signup/cdn1/phone-number/type")
                        props.popUpAlert(res.data.message, "UPDATE_AGENT_SUCCESS", "success");
                    }
                    else {
                        props.popUpAlert(res.message.response.data.message, "UPDATE_AGENT_SUCCESS", "error");
                    }
                }).catch(err => {
                    if (err.request.status === 402) {
                        window.location.href = "https://efone.ca/pricing"
                        localStorage.clear()
                    }
                    props.popUpAlert(err.message, "UPDATE_AGENT_SUCCESS", "error");
                })


            }

        }
    }

    React.useEffect(() => {
        setToken(localStorage.getItem("token"))
        if (localStorage.getItem("token")) {
            axiosInstance.get(`/sign-up?token=${localStorage.getItem("token")}`).then((res: any) => {
                if (res.data.data) {
                    const getdata = res.data?.data?.[0]
                    // setData(res.data?.data?.[0]) 
                    setData((prev: any) => {
                        return {
                            ...data,
                            email: getdata?.email,
                            confirmEmail: getdata?.email

                        }
                    })
                } else {
                }
            }).catch((err: any) => {
            })
        }

    }, [localStorage.getItem("token")])

    return (
        <>
            <div>
                <div>
                    <Paper elevation={2}
                        className={classes.headerPaper}
                    >
                        <img src={logo} alt="logo" style={{ width: "5%" }} />
                        <Typography style={{ color: '#fff', fontWeight: 600 }}>help@efone.ca</Typography>
                    </Paper>
                </div>
                <Grid>
                    <Paper elevation={5} className={classes.paper} >
                        <Grid>
                            <Typography className={classes.email}>Email Address</Typography>
                            <TextField variant="outlined" size='small'
                                value={data.email} className={classes.textfield}
                                name="email" onChange={handleChange} />
                            <p className={classes.err}>{formGroupData.map((fileds: any) => {
                                if (fileds.valid === false && fileds.field === 'email') {
                                    return fileds.message
                                } else {
                                    return ''
                                }
                            })}</p>
                            <Typography className={classes.confirmemail}>Confirm Email Address</Typography>
                            <TextField variant="outlined" size='small'
                                value={data.confirmEmail}
                                onPaste={handlePaste}
                                name="confirmEmail" onChange={handleChange} className={classes.textfield} />
                            <p className={classes.err}>{formGroupData.map((fileds: any) => {
                                if (fileds.valid === false && fileds.field === 'confirmEmail') {
                                    return fileds.message
                                } else {
                                    return ''
                                }
                            })}</p>
                            {/* local key - 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI */}
                            {/* productio n ket --6LdBbiAbAAAAAPsL9ZZj7cxRmc7LocsgicJbpatZ */}
                            <div className={classes.dbg}>
                                <ReCAPTCHA
                                    sitekey="6LdBbiAbAAAAAPsL9ZZj7cxRmc7LocsgicJbpatZ"
                                    onChange={handleClickcheck}
                                />
                            </div>
                            <div className={classes.divFloat}>
                                <Avatar className={classes.avatarBg} style={{ cursor: "pointer" }}>
                                    {token &&
                                        <ArrowForwardIcon className={classes.icon}
                                            onClick={tokenPatch}
                                        />}
                                    {subType && addon &&
                                        <ArrowForwardIcon className={classes.icon}
                                            onClick={handleClick}
                                        />}
                                    {token && subType && addon &&
                                        <ArrowForwardIcon className={classes.icon}
                                            onClick={handleClick}
                                        />}
                                </Avatar>
                            </div>
                        </Grid>

                    </Paper>
                    <div className={classes.paperOne}>
                        <Typography>Already have an account?</Typography>
                        <a href="https://app.efone.ca/">
                            <Typography className={classes.textColor}>Click to Sign In </Typography>
                        </a>
                    </div>
                </Grid>
            </div>
        </>
    );
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        popUpAlert: (err: any, instance: any, type: any) => dispatch(popUpActionCreator.initglobalPopup(err, instance, type))
    }
}

export default connect(null, mapDispatchToProps)(SigninPage)

