import React from 'react'
import { connect } from 'react-redux'
import HeaderComponent from '../../components/Header-component/HeaderComponent'
import * as globalPopuphandler from '../../store/Actions/popup-actionCreator'
import './layout.css'
import { makeStyles } from '@material-ui/core/styles';
import TextScroller from './Textscroll'
import axiosInstance from '../../environment/axiosInstances'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
const drawerWidth = 290;

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: 0,
    },
    menuButtonHidden: {
        display: 'none',
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(6),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7),
        },
    },
}));

function Layout(props: any) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [sidebar, setSidebar] = React.useState(false)
    const [getSignupDetails, setFetSignupDetails] = React.useState<any>({})
    const handleDrawerOpen = () => {
        setOpen(false);
    };

    const handleDrawerClose = () => {
        setOpen(true);
    };

    const handleSidebarSection = () => {
        setSidebar(!sidebar)
    }
    React.useEffect(() => {
        if (localStorage.getItem("token")) {
            axiosInstance.get(`/sign-up?token=${localStorage.getItem("token")}`).then((res: any) => {
                if (res.data.data) {
                    setFetSignupDetails(res.data?.data?.[0])
                } else {

                }
            }).catch((err: any) => {
            })
        }
    }, [localStorage.getItem("token")])

    return (
        <div className="layout_section">
            {/* pixel 992 */}
            {/* <div className="sidebar_container">
                <SidebarComponent />
            </div> */}
            {/* <div className="sidebar_container_responsive">
                {
                    sidebar && (
                        <>
                            <SidebarBackdrop handlePopupModal={handleSidebarSection} />
                            <SidebarPopUpModal>
                                <SidebarResponsive handlePopupModal={handleSidebarSection} />
                            </SidebarPopUpModal>
                        </>
                    )
                }
                
            </div> */}
            <div className="main_body_section">
                <div className="header_section">
                    <HeaderComponent />

                </div>
                {/* <div>
                    {getSignupDetails?.phoneNumber ? <TextScroller text={getSignupDetails?.phoneNumber} /> : ""}
                </div> */}
                <div className="childComponenet">
                    {props.children}
                    <ToastContainer autoClose={1000} />
                </div>
                <div className="footer_container">
                    {/* footer container */}
                    {/* <FooterComponent /> */}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        states: state
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        clearPoPUP: () => dispatch(globalPopuphandler.clearGlobalPopup())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
